<template>
    <div class="reward-card-container">
        <div v-if="isSending" class="is-sending">
            <b-spinner
                variant="light"
                size="is-small"
                :animation="{
                    duration: 1000,
                    delay: 200,
                    infinite: true
                }"
            />
        </div>
        <div class="reward-header">
            <div class="custom-row">
                <span class="reward-name">{{ reward.name }}</span>
            </div>
            <div class="custom-row">
                <span class="points">{{ reward.points }} {{ $t("rewardsRoute.points") }}</span>
                <div class="reward-redemption-info">
                    <span
                        v-if="reward.termsAndConditions"
                        @click="openRewardsInfoModal"
                    >
                        <info-icon-svg
                            class="info-icon"
                        />
                    </span>
                    <coupons-available-svg
                        class="info-icon"
                        :amount-added="amount"
                        :available-redemptions="availableRedemptions"
                    />
                </div>
            </div>
        </div>
        <reward-card
            :reward="reward"
            :available-redemptions="availableRedemptions"
        />
        <div class="reward-card-info">
            <div class="quantity-controls">
                <div class="remove-quantity" @click="removeQuantity">
                    &minus;
                </div>
                <div class="quantity-preview">
                    {{ amount }}
                </div>
                <div class="add-quantity" @click="addQuantity">
                    &plus;
                </div>
            </div>
            <div
                v-if="reward.validUntil"
                class="valid-until"
            >
                {{ $t('rewardsRoute.validUntil') }}
                <div
                    class="valid-until-preview"
                >
                    {{ validUntilFormatted }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RewardCard from '@/components/RewardCard'
    import InfoIconSvg from '@/components/svg/InfoIconSVG'
    import CouponsAvailableSvg from '@/components/svg/CouponsAvailableSVG'
    import dateUtils from '@/utils/date-conversion'
    import emitter from '@/utils/emitter'
    export default {
        components: {
            RewardCard,
            InfoIconSvg,
            CouponsAvailableSvg
        },
        props: {
            reward: {
                type: Object,
                required: true
            },
            availablePoints: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                amount: 0
            }
        },
        computed: {
            availableRedemptions() {
                const availableByPoints = Math.floor(this.availablePoints / this.reward.points)
                const availableByCoupons = this.reward.numberOfAvailableCodes
                return Math.min(availableByPoints, availableByCoupons)
            },
            isSending() {
                return this.$store.getters.isSendingRewards
            },
            validUntilFormatted() {
                return dateUtils.utcToDate(this.reward.validUntil)
            }
        },
        methods: {
            addQuantity() {
                if (this.amount < this.availableRedemptions && this.amount < this.reward.numberOfAvailableCodes) {
                    this.amount++
                    this.addItemToCart()
                }
            },
            removeQuantity() {
                if (this.amount > 0) {
                    this.amount--
                    this.removeItemFromCart()
                }
            },
            addItemToCart() {
                this.$store.commit('addRewardToCart', {
                    rewardLevel: this.reward,
                    amount: this.amount
                })
            },
            removeItemFromCart() {
                this.$store.commit('removeRewardFromCart', {
                    rewardLevel: this.reward,
                    amount: this.amount
                })
            },
            openRewardsInfoModal() {
                emitter.emit('openRewardsInfoModal', { termsAndConditions: this.reward.termsAndConditions })
            }
        }
    }
</script>

<style lang="scss" scoped>
.reward-card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    margin: 0 auto;
    padding: 1rem;
    border: 1px dashed var(--main-content-text);
    border-radius: 1.25rem;
    background: var(--white);
    color: var(--black);
    width: 100%;
    max-width: 350px;
}

.custom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.is-sending {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background: hsl(0deg 0% 0% / 50%);
    cursor: not-allowed;
    border-radius: 1.25rem;
    z-index: 2;
}

.reward-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.reward-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reward-card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 1rem;
}

.points {
    font-weight: 700;
    font-size: 1.1rem;
}

.quantity-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 120px;
    gap: 0.5rem;
}

.remove-quantity,
.add-quantity {
    font-weight: 700;
    cursor: pointer;
    font-size: 2.5rem;
}

.quantity-preview {
    background: var(--white);
    border-radius: 0.25rem;
    border: 1px solid var(--black);
    color: var(--black);
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0.25rem 1rem;
}

.reward-redemption-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.available-redemptions {
    font-weight: 700;
    font-size: 0.8rem;
    max-width: 350px;
    text-align: center;
}

.valid-until {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.valid-until-preview {
    font-weight: 700;
}

@media screen and (min-width: 992px) {
    .reward-header {
        width: 95%;
    }

    .available-redemptions {
        font-size: 1rem;
    }

    .custom-row {
        max-width: 600px;
    }

    .reward-name {
        font-size: 1.35rem;
    }

    .points {
        font-size: 1.25rem;
    }
}
</style>
