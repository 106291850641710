<template>
    <div class="coupons-available-container">
        <span class="available-redemptions">
            {{ remainingRedemptions }}
        </span>
        <svg
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            class="svg"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512;"
            xml:space="preserve"
        >
            <g>
                <g>
                    <path
                        d="M495.432,111.165H384.267c-4.427,0-8.017,3.589-8.017,8.017v8.551c0,5.01-4.076,9.086-9.086,9.086c-5.01,0-9.086-4.076-9.086-9.086v-8.551c0-4.427-3.589-8.017-8.017-8.017H16.568C7.432,111.165,0,118.597,0,127.733v256.534c0,9.136,7.432,16.568,16.568,16.568h333.495c4.427,0,8.017-3.589,8.017-8.017v-8.551c0-5.01,4.076-9.086,9.086-9.086c5.01,0,9.086,4.076,9.086,9.086v8.551c0,4.427,3.589,8.017,8.017,8.017h111.165c9.136,0,16.568-7.432,16.568-16.568V127.733C512,118.597,504.568,111.165,495.432,111.165z M495.967,247.983H264.551c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h231.415v120.25c0,0.295-0.239,0.534-0.534,0.534H392.284v-0.534c0-11.048-7.172-20.446-17.102-23.8v-18.956c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v18.956c-9.93,3.354-17.102,12.752-17.102,23.8v0.534H195.608V332.96c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017v51.841H16.568c-0.295,0-0.534-0.239-0.534-0.534v-120.25h94.597c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017H16.033v-120.25c0-0.295,0.239-0.534,0.534-0.534h163.006v51.841c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-51.841h146.438v0.534c0,11.048,7.172,20.446,17.102,23.8v18.956c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-18.956c9.93-3.354,17.102-12.752,17.102-23.8v-0.534h103.148c0.295,0,0.534,0.239,0.534,0.534V247.983z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M367.165,196.676c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-17.102C375.182,200.266,371.592,196.676,367.165,196.676z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M367.165,282.188c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-17.102C375.182,285.777,371.592,282.188,367.165,282.188z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M269.664,282.76c-11.555-11.555-34.253-20.759-52.59-26.76c18.337-6.001,41.034-15.205,52.589-26.761c7.387-7.387,11.455-17.209,11.455-27.657c0-10.446-4.068-20.268-11.455-27.657s-17.208-11.454-27.655-11.454c-10.447,0-20.269,4.068-27.657,11.455c-11.555,11.555-20.759,34.253-26.761,52.589c-6.002-18.337-15.205-41.034-26.761-52.589c-7.387-7.387-17.209-11.455-27.657-11.455c-10.446,0-20.268,4.068-27.656,11.455c-7.388,7.387-11.453,17.209-11.453,27.657c0,10.447,4.068,20.269,11.455,27.657c11.555,11.555,34.253,20.759,52.589,26.761c-18.337,6.001-41.034,15.205-52.589,26.761c-7.387,7.385-11.455,17.207-11.455,27.654c0,10.446,4.068,20.268,11.455,27.657c7.387,7.388,17.209,11.455,27.657,11.455c10.447,0,20.269-4.068,27.657-11.455c11.555-11.555,20.759-34.253,26.761-52.589c6.002,18.337,15.205,41.034,26.761,52.589c7.387,7.387,17.209,11.455,27.656,11.455c10.446,0,20.268-4.068,27.657-11.455c7.388-7.387,11.453-17.209,11.453-27.657S277.051,290.147,269.664,282.76z M116.855,217.904c-4.358-4.36-6.759-10.156-6.759-16.32s2.401-11.96,6.76-16.319s10.154-6.76,16.319-6.76s11.96,2.401,16.319,6.76c11.471,11.471,21.483,40.353,26.924,59.566C157.212,239.395,128.342,229.39,116.855,217.904z M149.493,326.735c-4.359,4.359-10.154,6.76-16.319,6.76s-11.96-2.401-16.319-6.76s-6.76-10.154-6.76-16.319s2.401-11.96,6.76-16.319c11.471-11.471,40.353-21.483,59.566-26.924C170.984,286.378,160.98,315.249,149.493,326.735z M225.688,185.265c4.359-4.359,10.155-6.76,16.319-6.76s11.96,2.401,16.319,6.76s6.76,10.154,6.76,16.319s-2.401,11.96-6.76,16.319c-11.471,11.471-40.353,21.483-59.566,26.924C204.197,225.622,214.202,196.751,225.688,185.265z M258.326,326.735c-4.359,4.359-10.154,6.76-16.319,6.76s-11.96-2.401-16.319-6.76c-11.471-11.471-21.483-40.353-26.924-59.566c19.206,5.436,48.076,15.441,59.563,26.928c4.359,4.359,6.76,10.154,6.76,16.319S262.685,322.376,258.326,326.735z"
                    />
                </g>
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    </div>
</template>

<script>
    export default {
        props: {
            availableRedemptions: {
                type: Number,
                default: 0
            },
            amountAdded: {
                type: Number,
                default: 0
            }
        },
        computed: {
            remainingRedemptions() {
                return this.availableRedemptions - this.amountAdded
            }
        }
    }
</script>

<style lang="scss" scoped>
.coupons-available-container {
    position: relative;
}

.available-redemptions {
    position: absolute;
    top: 0;
    right: -10px;
    font-size: 0.7rem;
    color: #fff;
    background-color: #ff5a5f;
    border-radius: 50%;
    font-weight: bold;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
}

.svg {
    fill: var(--black);
    width: 40px;
}

@media screen and (min-width: 992px) {
    .svg {
        fill: var(--black);
        width: 45px;
    }
}
</style>
