<template>
    <div
        class="reward-coupon"
        :class="{'not-available': reward.numberOfAvailableCodes <= 0 || availableRedemptions <= 0}"
        :style="{
            backgroundImage: 'url(' + reward.imageUrl + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }"
    />
</template>

<script>
    export default {
        props: {
            reward: {
                type: Object,
                required: true
            },
            availableRedemptions: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.reward-coupon {
    position: relative;
    width: 100%;
    max-width: 350px;
    height: 224px;
    border-radius: 1.25rem;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
}

.not-available {
    opacity: 0.6;
    filter: grayscale(100%);
    cursor: not-allowed;
}

</style>
